import React, { useEffect, useState } from 'react';
import { NavLink as RRNav } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Footer from "../../components/Footer";
import Header from "../../components/Home/Header";
import stepMobile from '../../img/SixStepsPainVert.svg';
import fr_stepMobile from '../../img/SixÉtapesDouleurVert.svg';
import steps from '../../img/SixStepsPainHoriz.svg';
import fr_steps from '../../img/SixÉtapesDouleurHoriz.svg';
import IdleComponent from '../../components/IdleComponent';
import "./Intro.css";
import { Event } from '../../components/GoogleAnalytics/GA';
import { logout } from '../../components/AuthenticationService';
import Subheader from '../../components/UI/Subheader/Subheader';
import Joyride from 'react-joyride';

const Intro = props => {
  let lang = localStorage.getItem("language");
  const isEng = lang === "English";

  const [navTo, setNavTo] = useState('');
  const [showTour, setShowTour] = useState(false);
  const [tourSteps] = useState([
    {
      target: ".pain-step",
      content: isEng ? "You can describe your pain and treatments" : "Vous pouvez décrire votre douleur et vos traitements",
    },
    {
      target: ".important-step",
      content: isEng ? "You can clarify what is important to you when managing your pain" : "Vous pouvez clarifier ce qui est important pour vous afin de soulager votre douleur",
    },
    {
      target: ".review-step",
      content: isEng ? "You can review suggested treatments that match your preferences and others that you are interested in. You will be able to select your favourites" : "Vous pouvez consulter les traitements suggérés qui correspondent à vos préférences et d’autres qui vous intéressent. Vous pouvez sélectionner vos favoris",
    },
    {
      target: ".plan-step",
      content: isEng ? "You can make your plan" : "Vous pouvez élaborer votre plan",
    },
    {
      target: ".track-step",
      content: isEng ? "You can track your pain and see if treatments work for you" : "Vous pouvez suivre votre douleur et voir si les traitements fonctionnent pour vous",
    },
    {
      target: ".summary-step",
      content: isEng ? "You will see a summary of all your answers that you can share with your health care team" : "Vous verrez un résumé de toutes vos réponses que vous pourrez partager avec votre équipe soignante",
    }, {
      target: ".reminder-step",
      content: isEng ? "You can set reminders to remember to use the app and track your pain.\nYou can change your answers in any step and it will give you new ways to manage your pain! For accurate results, please save your answers on each page." : "Vous pouvez définir des rappels pour vous rappeler d’utiliser l’application et suivre votre douleur.\nVous pouvez modifier vos réponses à n’importe quelle étape et cela vous donnera de nouvelles façons de soulager votre douleur. Pour des résultats précis, veuillez enregistrer vos réponses sur chaque page."
    }
  ]);

  const [saved, setSaved] = useState(true);
  const [navBy, setNavBy] = useState('');
  const [displayNavDialog, setDisplayNavDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);  // Modal visibility state

  const handleStepperNav = to => {
    props.history.push(to)
  }

  useEffect(() => {
    const tourShown = localStorage.getItem('tourShown');
    if (!tourShown) {
      setShowModal(true);  // Show the modal on page load
    }
  }, []);

  const handleTourStart = () => {
    setShowTour(true);    // Start the tour
    setShowModal(false);   // Close the modal
  };

  const handleTourEnd = () => {
    localStorage.setItem('tourShown', 'true');
    setShowTour(false);
  };

  const handleSubHeaderLogout = () => {
    Event('Subheader', 'click', 'Logout');
    if (saved) {
      logout();
      props.history.push(`/`);
    } else {
      setNavBy('logout');
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderProgressNav = (loc) => {
    loc = loc ? loc : "";
    Event('Navigation Button' + `-${loc}`, 'click', 'Track progress');
    if (saved) {
      props.history.push(`/userPanel/average`);
    } else {
      setNavBy('user');
      setDisplayNavDialog(true);
    }
  };

  const handleSubHeaderAdminNav = () => {
    if (saved) {
      props.history.push(`/adminPanel/users`);
    } else {
      setNavBy('admin');
      setDisplayNavDialog(true);
    }
  };

  return (
    <div className="wrapper intro">
      <div className="page-subheader">
        <Subheader
          title=""
          handleAdmin={handleSubHeaderAdminNav}
          handleUser={() => handleSubHeaderProgressNav("tp")}
          handleLogout={handleSubHeaderLogout}
          showTour={localStorage.getItem('tourShown')}
          setShowTour={setShowTour}
        />
      </div>
      <IdleComponent history={props.history} />
      <div className="page-header">
        <Header current={props} handleNav={handleStepperNav} setNavTo={setNavTo} />
      </div>

      <div className="body-container">
        <h1 className="six-steps-heading"><b>{isEng ? "This application has six steps" : "Cette application a six étapes"}</b></h1>
        <div className='body-container-intro'>
          <Button className="next-btn" tag={RRNav} to="/home" onClick={() => { Event('Navigation Button-tp', 'click', 'Next'); }}>
            {isEng ? "Back" : "Retour"}
          </Button>

          <picture className="steps-container">
            <source media="(max-width: 660px)" className="steps-img" srcSet={isEng ? stepMobile : fr_stepMobile} alt="six-steps" />
            <img media="(min-width: 661px)" className="steps-img text-center" srcSet={isEng ? steps : fr_steps} alt="six-steps" />
          </picture>

          <Button className="next-btn" tag={RRNav} to="/questionnaire" onClick={() => { Event('Navigation Button-tp', 'click', 'Next'); }}>
            {isEng ? "Next" : "Suivant(e)"}
          </Button>
        </div>
      </div>
      <Footer />

      {/* Modal to prompt user to start the tour */}
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader toggle={() => setShowModal(!showModal)}>
          {isEng ? "Take a Tour" : "Faire une visite guidée"}
        </ModalHeader>
        <ModalBody>
          {isEng
            ? "Would you like to take a quick tour to learn about the features? Click on the red spotlight :)"
            : "Voulez-vous faire une visite guidée pour découvrir les fonctionnalités?"}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleTourStart}>
            {isEng ? "Start Tour" : "Commencer la visite"}
          </Button>
          <Button color="secondary" onClick={() => setShowModal(false)}>
            {isEng ? "Maybe Later" : "Peut-être plus tard"}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Joyride for the tour */}
      {showTour && (
        <Joyride
          steps={tourSteps}
          continuous
          showProgress
          run
          callback={data => {
            if (data.status === "finished" || data.status === "skipped") handleTourEnd();
          }}
        />
      )}
    </div>
  );
};

export default Intro;
