import React from 'react';
import MainOption from './MainOption';
import mainOptions from '../../data/main-options.json'
import { Event } from './../GoogleAnalytics/GA';
import warning from '../../img/warning.png';
import { NavLink as RRNav } from "react-router-dom";
import { Button, Row } from "reactstrap";

import './Main.css'

//Get value of language from local storage
let lang = localStorage.getItem("language")

const Main = ({ current }) => {

  return (
    <>
      <div className="orangenext-btn-content">
        <Button className="next-btn" tag={RRNav} to="/intro" onClick={() => { Event('Navigation Button-tp', 'click', 'Next'); }}>
          {lang === "English" ? "Start" : "Commencer"}
        </Button>
      </div>

      <div className={`body-container ${current.match.path === "/home" ? "home-margin" : ""}`}>
        <div className="main-options">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ textAlign: 'center' }}>
              <h1>
                {lang === "English"
                  ? "The JIA Option Map"
                  : "La Carte d’options en AJI"}
              </h1>
              <h4>
                {lang === "English"
                  ? "can help young people and parents choose other ways to manage juvenile idiopathic arthritis (JIA) pain in addition to prescribed treatments.You can use it if…"
                  : "peut aider les jeunes et les parents à choisir d’autres façons de gérer la douleur associée à l’arthrite juvénile idiopathique (AJI) en plus des traitements prescrits. Vous pouvez l’utiliser si…"}
              </h4>
            </div>
          </div>
          <Row>
            {mainOptions.options &&
              mainOptions.options.map((option) => (
                <MainOption key={option.id} desc={option} keyId={option.id} />
              ))}
          </Row>
        </div>
        <div className='home-usage'>
          <p>
            {lang === "English" ?
              "You can use this app as often as you want to find new ways to manage your pain and to track your progress! You can also share your information with your health care team." :
              "Vous pouvez utiliser cette application aussi souvent que vous le souhaitez pour trouver de nouvelles façons de gérer votre douleur et suivre vos progrès! Vous pouvez également partager vos informations avec votre équipe soignante."}
          </p>
        </div>
        <div className="disclaimer">
          <img src={warning} alt="warning icon" />
          <div>
            <p>
              {lang === "English" ?
                "Keep taking your prescribed treatments so that your arthritis does not get worse." :
                "Continuez à prendre vos traitements prescrits afin que votre arthrite ne s'aggrave pas."}
            </p>
          </div>
        </div>
      </div >
      {/* <div className="next-btn-container"> */}
      {/* <div className="next-btn-content">
          <Button className="next-btn" tag={RRNav} to="/intro" onClick={() => { Event('Navigation Button-bt ', 'click', 'Next'); }}>
            {lang === "English" ? "Next" : "Suivant(e)"}
          </Button>
        </div> */}
      {/* </div> */}
    </>
  );
};

export default Main
