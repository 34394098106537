import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';
import { NavLink as RRNav } from 'react-router-dom';
import "./Subheader.css"
import { type, logout, getUserType } from '../../AuthenticationService';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'reactstrap';
import Notice from '../../../Containers/Notice/Notice';
import { getRequest } from '../../../API/ApiHandler';

const Subheader = ({ title, history, handleAdmin, handleUser, handleLogout, showTour, setShowTour }) => {
  const [user, setUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [reminderStatus, setReminderStatus] = useState()

  // Toggle modal open/close state
  const toggleModal = () => setModalOpen(!modalOpen);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.text.secondary,
      color: 'rgba(255, 255, 255, 1)',
      boxShadow: theme.shadows[3],
      fontSize: 12
    },
  }))(Tooltip);

  const getUser = async () => {
    let response = await getRequest('/auth/users/current/me');
    let user = '';
    if (response) {
      user = response.data.id;
    }
    setUser(user);
  }

  useEffect(() => {
    getUser();
    getUserType()
    getReminderData(setReminderStatus)
  }, [modalOpen, reminderStatus]);



  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  const getReminderData = async (cb) => {
    let response = await getRequest(`/reminders/${user}`);
    if (!response || !Object.keys(response).includes('user_id')) {
      // No reminder data
      cb(false)
    } else {
      cb(true)
    }
  }

  return (
    <div className='sub-header'>
      <div className="subheader-content">
        <h3>{title}</h3>
        <div className="subheader-right">
          {type === 'admin' && <LightTooltip title={lang === "English" ? "Click to access the admin panel" : "Cliquez pour accéder au panneau d'administration"} placement="top" arrow>
            <Button className="subheader-btn" onClick={handleAdmin}>{lang === "English" ? "Admin panel" : "Panneau d'administration"}</Button>
          </LightTooltip>}
          {
            showTour &&
            <LightTooltip title={lang === "English" ? "Show tour" : "Faire le tour"} placement="top" arrow>
              <Button className="subheader-btn" onClick={() => { localStorage.removeItem('tourShown'); setShowTour(true) }}>{lang === "English" ? "Show tour" : "Faire le tour"}</Button>
            </LightTooltip>
          }
          <LightTooltip title={lang === "English" ? "Click to set reminders" : "Cliquez pour programmer des rappels"} placement="top" arrow>
            <Button className="subheader-btn reminder-step" onClick={toggleModal}>{lang === "English" ? `Reminders ${reminderStatus ? "on" : "off"}` : `Rappels ${reminderStatus ? "activés" : "désactivés"}`}</Button>
          </LightTooltip>

          <LightTooltip title={lang === "English" ? "Click to logout" : "Cliquez pour vous déconnecter"} placement="top" arrow>
            <Button className="subheader-btn logout" onClick={handleLogout}>{lang === "English" ? "Logout" : "Se déconnecter"}</Button>
          </LightTooltip>
        </div>
      </div>
      {modalOpen && <Notice modalOpen={modalOpen} toggleModal={toggleModal} />}
    </div>
  )
}

export default withRouter(Subheader)
