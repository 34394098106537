import React, { useState, useEffect } from 'react';
import { Row } from "reactstrap";
import TreatmentCard from '../TreatmentCard'
import { handleRecommendations } from "../../components/GenerateRecommendations"
import { getRequest } from "../../API/ApiHandler"
import warning from '../../img/warning.png';
import FavouriteIcon from '../UI/Icons/FavouriteIcon'

const Recommendations = ({ favs, setFavs }) => {
  const [topThreeSC, setTopThreeSC] = useState()
  const [topThreeHCP, setTopThreeHCP] = useState()
  const [favourites, setFavourites] = useState()

  const getUserSC = async () => {
    let data = await getRequest(`/userScs/user`)
    if (data.length) {
      setTopThreeSC(data)
    } else {
      await handleRecommendations()
      getUserSC()
    }
  }

  const getUserHCP = async () => {
    let data = await getRequest(`/userHcps/user`)
    if (data.length) {
      setTopThreeHCP(data)
    } else {
      await handleRecommendations()
      getUserHCP()
    }
  }

  useEffect(() => {
    getUserSC()
    getUserHCP()
  }, [])

  useEffect(() => {
    if (favs) setFavourites(favs)
  }, [favs])

  const topThreeSCList = topThreeSC &&
    topThreeSC.map(treatment =>
      <TreatmentCard
        key={treatment.id}
        treatment={treatment}
        selected={favs}
        setSelected={setFavs}
        icon="fav"
      />
    )

  const topThreeHCPList = topThreeHCP &&
    topThreeHCP.map(treatment =>
      <TreatmentCard
        key={treatment.id}
        treatment={treatment}
        selected={favourites}
        setSelected={setFavs}
        icon="fav"
      />
    )

  //Get value of language from local storage
  let lang = localStorage.getItem("language")

  const outline = { fill: '#FFFFFF', stroke: 'rgba(0, 0, 0, 1)', strokeMiterlimit: 10, strokeWidth: '8px' }

  return (
    <React.Fragment>
      <div className="notice">
        <h5 style={{ marginBottom: "25px" }}>
          {lang === "English" ?
            "Here is a list of suggested treatments you may like to try now or talk about with your health care team." :
            "Voici une liste de traitements que vous pourriez essayer dès maintenant ou dont vous pourriez parler avec votre équipe soignante."}
        </h5>
        <div>
          <h5>{lang === "English" ? "Each treatment is colour-coded to describe how recommended it is by experts:" : "Chaque traitement est codé par couleur pour décrire à quel point il est recommandé par les experts :"}</h5>
          <h5 style={{ display: 'flex', alignItems: 'center' }}><div style={{ height: "25px", width: "25px", background: 'green', marginRight: "10px", border: "1px black solid" }}></div>: {lang === "English" ? "Some evidence that the intervention is effective and safe and usually recommended by experts." : "Certaines preuves que le traitement est efficace et sûre et généralement recommandé par des experts."}</h5>
          <h5 style={{ display: 'flex' }}><div style={{ height: "25px", width: "25px", background: 'yellow', marginRight: "10px", border: "1px black solid" }}></div> : {lang === "English" ? "Uncertain evidence that the intervention is effective and safe, may sometimes be recommended by experts." : "Preuve incertaine que le traitement est efficace et sûre, peut parfois être recommandé par des experts."}</h5>
          <h5 style={{ display: 'flex', alignItems: 'center' }}><div style={{ height: "25px", width: "25px", background: 'red', marginRight: "10px", border: "1px black solid" }}></div> : {lang === "English" ? "Usually not recommended by experts because of potential side effects." : "Généralement déconseillé par les experts en raison des effets secondaires potentiels. "}</h5>
          <h5 style={{ marginTop: '25px' }}>{lang === "English" ? "The choice will depend on each person’s characteristics and context." : "Le choix dépendra des caractéristiques et du contexte de chaque personne."}</h5>
        </div>
      </div>
      <div className="review-content">
        {/*****************
         SC TREATMENTS
          ******************/}
        <div className="title-padding">
          <h4>{lang === "English" ? "Treatments you could try now" : "Traitements que vous pourriez essayer dès maintenant."}</h4>
        </div>
        <Row className="card-row">
          {topThreeSCList}
        </Row>
      </div>
      {/*****************
       HCP TREATMENTS
        ******************/}
      <div className="review-content">
        <div className="title-padding">
          <h4>{lang === "English" ?
            "Treatments you could talk about with your health care team before trying" :
            "Traitements dont vous pourriez parler avec votre équipe soignante avant de les essayer"}
          </h4>
        </div>
        <Row className="card-row">
          {topThreeHCPList}
        </Row>
      </div>
      <div className="notice review-description-div">
        <div className="disclaimer">
          <img src={warning} alt="warning" />
          <div>
            <p>
              {lang === "English" ?
                "Please consider the benefits, risks and how easy it is to use the treatments before making a selection. You can also discuss it with your health care team." :
                "N’oubliez pas de considérer les avantages, les risques et la facilité d’utilisation de ces traitements avant de faire un choix. Vous pouvez également en discuter avec votre équipe soignante."}
            </p>
            <p>
              {lang === "English" ?
                "Keep taking your prescribed treatments so that your arthritis does not get worse." :
                "Pour éviter que votre arthrite s’aggrave, continuez à suivre les traitements qui vous ont été prescrits."}
            </p>
          </div>
        </div>
      </div>
      {/* <div className="notice">
        <h5>
          {lang === "English" ?
            "Here are “Suggested treatments” for you based on where you have pain, the types of treatments you prefer and what scientific studies say. Review treatments and click on “Read more” if you want information on each treatment. Save those you prefer to your favourites by clicking on the (picture of heart). You can click on the “All Treatments” tab to see all treatments, and click on the “My Favourites” tab to review your favourites. You can also go back to Step 2 to change your answers and see new treatments." :
            "Voici des « Traitements suggérés » en fonction de l'endroit où vous ressentez de la douleur, des types de traitements que vous préférez et de ce que disent les études scientifiques. Passez en revue les traitements et cliquez sur « Lire la suite » si vous souhaitez des informations sur chaque traitement. Enregistrez ceux que vous préférez dans vos favoris en cliquant sur le (put picture of heart). Vous pouvez cliquer sur l'onglet « Tous les traitements » pour voir tous les traitements, puis cliquer sur l'onglet « Mes favoris » pour consulter vos favoris. Vous pouvez également revenir à l'étape 2 pour modifier vos réponses et voir de nouveaux traitements."}
        </h5>
      </div> */}
    </React.Fragment>
  )
}

export default Recommendations