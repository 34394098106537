import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Reminders from '../Reminders/Reminders';
import './Notice.css';

const Notice = ({ modalOpen, toggleModal }) => {
  const [didSelect, setDidSelect] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('purpose')) setDidSelect(true);
  }, []);

  // Get language from local storage
  const lang = localStorage.getItem("language");

  return (
    <>
      <Modal isOpen={modalOpen} toggle={toggleModal} centered >
        <ModalHeader toggle={toggleModal}>
          {lang === "English" ? "Important Notice" : "Avis Important"}
        </ModalHeader>
        <ModalBody>
          <Reminders useModal={true} />

          <div className="text-center mt-3">
            <p>{lang === "English" ? "It is recommended to set at least weekly reminders to monitor your pain and know which treatments work for you." : "Il est recommandé de définir des rappels à chaque semaine ou plus souvent pour suivre votre douleur et savoir quels traitements fonctionnent pour vous."}</p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Notice;
