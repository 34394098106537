import React, { useState } from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";

const LinksMenuEn = ({ link, open, toggle }) => {
  return (
    <React.Fragment>
      <AccordionItem>
        <AccordionHeader targetId={link.id}>
          <span>{link.name}</span>
          <span class="collapse-text">
            {open.includes(link.id) ? "(Collapse)" : "(Expand)"}
          </span>
        </AccordionHeader>

        {link.links.map((web) => (
          <AccordionBody accordionId={link.id}>
            <p>
              <a href={`${web.link}`} target="_blank" rel="noopener noreferrer">
                {web.name ? `${web.name}` : `${web.link}`}
              </a>
            </p>
          </AccordionBody>
        ))}
      </AccordionItem>
    </React.Fragment>
  );
};

export default LinksMenuEn;
