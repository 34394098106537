import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import "./Header.css"
import TranslateButton from "../../components/UI/TranslateButton";
import logo_en from '../../img/logo_en.png';
import logo_fr from '../../img/logo_fr.png';
import { Event } from '../GoogleAnalytics/GA';

//Get value of language from local storage
let lang = localStorage.getItem("language")

export default class Header extends Component {
  handleClick(ev, link) {
    ev.preventDefault()
    this.props.handleNav(link)
    this.props.setNavTo(link)
  }

  render() {
    return (
      <div>
        {(this.props.current.match.path === "/home" || this.props.current.match.path === "/notice") &&
          <div className='home-header'>
            <div className="home-logo-header">
              <NavLink style={{ display: 'flex', justifyContent: 'center', flexGrow: '1' }} to="/home"><img className="logo-home" alt="logo" src={lang === "English" ? logo_en : logo_fr} /></NavLink>
              <div>
                <div className="home-logo-header-translation"> <TranslateButton /> </div>
              </div>
            </div>
          </div>
        }
        {(this.props.current.match.path !== "/home" && this.props.current.match.path !== "/notice") &&
          <div className="header">
            <div className={"header-container"}>
              <div>
                <div style={{ padding: '1.5rem' }}> <TranslateButton /> </div>
              </div>
              <ul className="tab-nav-header">
                <div className="tab-steps">
                  <NavLink className="tab-nav-header-item tab-nav-header-navlink pain-step" to='/questionnaire' onClick={(ev) => { this.handleClick(ev, "/questionnaire"); Event('Header', 'click', 'Pain and treatments'); }}>
                    <p>{lang === "English" ? "Pain & treatments" : "Douleur et traitements"}</p>
                    <div className="tab-navlink-div">
                      <h2>1</h2>
                    </div>
                  </NavLink>
                  <NavLink className={lang === "English" ? "tab-nav-header-item tab-nav-header-navlink important-step" : "tab-nav-header-item tab-nav-header-navlink tab-nav-header-navlink-fr-2 important-step"} to='/whats-important-to-you' onClick={(ev) => { this.handleClick(ev, "/whats-important-to-you"); Event('Header', 'click', 'Whats important'); }}>
                    <p>{lang === "English" ? "What's important to you" : "Ce qui est important pour vous"}</p>
                    <div className={lang === "English" ? "tab-navlink-div" : "tab-navlink-div-fr"}>
                      <h2>2</h2>
                    </div>
                  </NavLink>

                  {this.props.current.match.path === "/treatment-details/:treatment" ?
                    <NavLink className="tab-nav-header-item tab-nav-header-navlink review-step" to='/treatment-details' onClick={(ev) => { this.handleClick(ev, "/review-and-select"); Event('Header', 'click', 'Review & Select-Treatement'); }}>
                      <p>{lang === "English" ? "Review & select" : "Réviser et sélectionner"}</p>
                      <div className="tab-navlink-div">
                        <h2>3</h2>
                      </div>
                    </NavLink>

                    :

                    <NavLink className="tab-nav-header-item tab-nav-header-navlink review-step" to='/review-and-select' onClick={(ev) => { this.handleClick(ev, "/review-and-select"); Event('Header', 'click', 'Review & Select'); }}>
                      <p>{lang === "English" ? "Review & select" : "Réviser et sélectionner"}</p>
                      <div className="tab-navlink-div">
                        <h2>3</h2>
                      </div>
                    </NavLink>

                  }

                  <NavLink className="tab-nav-header-item tab-nav-header-navlink plan-step" to='/make-your-plan' onClick={(ev) => { this.handleClick(ev, "/make-your-plan"); Event('Header', 'click', 'Plan'); }}>
                    <p>{lang === "English" ? "Plan" : "Plan"}</p>
                    <div className="tab-navlink-div">
                      <h2>4</h2>
                    </div>
                  </NavLink>
                  <NavLink className="tab-nav-header-item tab-nav-header-navlink track-step" to='/userPanel/average' onClick={(ev) => { this.handleClick(ev, "/userPanel/average"); Event('Header', 'click', 'Track progress'); }}>
                    <p>{lang === "English" ? "Track pain" : "Suivre la douleur"}</p>
                    <div className="tab-navlink-div">
                      <h2>5</h2>
                    </div>
                  </NavLink>
                  <NavLink className="tab-nav-header-item tab-nav-header-navlink summary-step" to='/summary' onClick={(ev) => { this.handleClick(ev, "/summary"); Event('Header', 'click', 'Summary'); }}>
                    <p>{lang === "English" ? "Summary" : "Résumé"}</p>
                    <div className="tab-navlink-div">
                      <h2>6</h2>
                    </div>
                  </NavLink>
                </div>
              </ul>
              <div className='main-container' style={{ justifySelf: 'center' }} >
                <NavLink to="/home" onClick={() => { Event('Header', 'click', 'Home logo'); }}>
                  <img className="logo-link" alt="logo" src={lang === "English" ? logo_en : logo_fr} />
                </NavLink>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
